import React from 'react';

const About = () => {
  return (
    <section>
      <h2>About Us</h2>
      <p>We are a passionate team of designers...</p>
    </section>
  );
};

export default About;
