import React from 'react';

const Home = () => {
  return (
    <section>
      <h2>Welcome to Our Interior Design Company</h2>
      <p>Your dream space awaits!</p>
    </section>
  );
};

export default Home;
