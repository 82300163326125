import React from 'react';

const Gallery = () => {
  return (
    <section>
      <h2>Our Work</h2>
      <p>Check out some of our latest projects!</p>
      {/* Images or a gallery component can be added here */}
    </section>
  );
};

export default Gallery;
