import React from 'react';

const Services = () => {
  return (
    <section>
      <h2>Our Services</h2>
      <ul>
        <li>Residential Design</li>
        <li>Commercial Design</li>
        <li>Space Planning</li>
        <li>Color Consultation</li>
      </ul>
    </section>
  );
};

export default Services;
